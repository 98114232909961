<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select v-model="corp_id" @change="corpChange" style="width: 300px;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="用户名">
					<a-input size="default" v-model="user_name" placeholder="输入用户名查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="姓名">
					<a-input size="default" v-model="real_name" placeholder="输入姓名查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="日期查询">
					<a-range-picker :allowClear="false" :default-value="[start_date, end_date]" :key="start_date"
						@change="onChange" style="width:240px;">
						<a-icon slot="suffixIcon" type="smile" />
					</a-range-picker>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div class="btnss">
				<a-button type="primary" icon="check-circle" @click="tocheck()">
					批量确认
				</a-button>
				<a-button type="primary" style="margin-left: 10px;" icon="close-circle" @click="nocheck()">
					批量取消
				</a-button>
				<p v-if="selectedRowKeys.length">已选条数：{{selectedRowKeys.length}}</p>
			</div>
			<a-button type="primary" icon="plus-circle" @click="dcClick">
				导出
			</a-button>
		</div>

		<div class="table-box">
			<a-table rowKey="order_id" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
				:columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="status" slot-scope="text, record">
					<a-tag color="green" v-if="record.status == 1">
						确认
					</a-tag>
					<a-tag color="red" v-if="record.status == 0">
						未确认
					</a-tag>
				</div>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" @click="detail(record.order_id)">
						详情
					</span>
					<span class="btn-span" v-if="record.status == 0" @click="tocheck(record.order_id)">
						确认
					</span>
					<span class="btn-span" v-if="record.status == 1" style="color: red;"
						@click="nocheck(record.order_id)">
						取消
					</span>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
					:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>

		<a-modal title="需求详情" :visible="visible" width="500px" :confirm-loading="confirmLoading" :footer="null"
			@cancel="() => {visible = false}">
			<div v-for="(item) in detailData" class="boboox">
				<p>{{item.type_name}}</p>
				<a-table :columns="columns1" size="small" :pagination="false" :data-source="item.list" bordered>
				</a-table>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	import moment from 'moment'

	const columns = [{
			title: '网点名称',
			dataIndex: 'name',
			key: 'name',
		}, {
			title: '用户名',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: '姓名',
			key: 'real_name',
			dataIndex: 'real_name',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			scopedSlots: {
				customRender: 'status'
			},
		},
		{
			title: '需要日期',
			dataIndex: 'want_time',
			key: 'want_time',
		},
		{
			title: '提交时间',
			dataIndex: 'add_time',
			key: 'add_time',
		},
		{
			title: '操作',
			key: 'age6',
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];
	const columns1 = [{
			title: '面额',
			dataIndex: 'de_name',
			key: 'de_name',
		},
		{
			title: '数量',
			dataIndex: 'qty',
			key: 'qty',
		},
		{
			title: '单位',
			key: 'unit_name',
			dataIndex: 'unit_name',
		},
	];
	export default {
		data() {
			return {
				columns,
				corp_id: null,
				user_name: '',
				real_name: '',
				start_date: moment().add(1, 'days').format('YYYY-MM-DD'),
				end_date: moment().add(1, 'days').format('YYYY-MM-DD'),
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],

				visible: false,
				columns1,
				detailData: [],

				tablePage1: 1,
				tableTotal1: 0,
				detailId: null,
				confirmLoading: true,

				selectedRowKeys: [],
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}

				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {
							...corpTreeList
						}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			moment,
			onSelectChange(selectedRowKeys) {
				this.selectedRowKeys = selectedRowKeys;
			},
			disabledDate(current) {
				return current && current > moment().endOf('day')
			},
			onChange(data) {
				this.start_date = moment(data[0]).format('YYYY-MM-DD')
				this.end_date = moment(data[1]).format('YYYY-MM-DD')
			},
			handleSubmit(e) {},
			corpChange(data) {
				this.corp_id = data
			},
			chcorpChange(data) {
				this.form.corp_id = data[data.length - 1]
			},
			tabelPageChange1(page) {
				this.tablePage1 = page
				this.detail()
			},
			detail(order_id) {
				http({
					method: 'post',
					url: '/api.order.detail/',
					data: {
						order_id: order_id,
					}
				}).then((res) => {
					this.detailData = res.data
					this.visible = true
				})
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.user_name = ''
					this.real_name = ''
					this.start_date = moment().add(1, 'days').format('YYYY-MM-DD')
					this.end_date = moment().add(2, 'days').format('YYYY-MM-DD')
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			getTable() {
				this.selectedRowKeys = []
				http({
					method: 'post',
					url: '/api.order.list/',
					data: {
						user_name: this.user_name,
						real_name: this.real_name,
						page: this.tablePage,
						name: this.name,
						number: this.number,
						start_date: this.start_date,
						end_date: this.end_date,
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			dcClick() {
				http({
					method: 'post',
					url: '/api.order.export/',
					data: {
						user_name: this.user_name,
						real_name: this.real_name,
						page: this.tablePage,
						name: this.name,
						number: this.number,
						start_date: this.start_date,
						end_date: this.end_date,
						corp_id: this.corp_id
					}
				}).then((res) => {
					if (res.code == '200') {
						window.open(res.data)
					} else {
						this.$message.error(res.message);
					}
				})
			},
			tocheck(order_id) {
				let odids = ''
				if (order_id) {
					odids = order_id
				} else {
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请至少勾选一条订单！');
						return
					}
					odids = this.selectedRowKeys.join()
				}
				this.$confirm({
					title: '是否操作订单确认？',
					onOk: () => {
						http({
							method: 'post',
							url: '/api.order.tocheck/',
							data: {
								order_id: odids
							}
						}).then((res) => {
							if (res.code == "200") {
								this.$message.success('订单操作成功！');
								this.selectedRowKeys = []
								this.getTable()
							} else {
								this.$message.warning(res.message);
							}
						})
					},
					onCancel() {
						console.log('Cancel');
					},
					class: 'test',
				});

			},
			nocheck(order_id) {
				let odids = ''
				if (order_id) {
					odids = order_id
				} else {
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请至少勾选一条订单！');
						return
					}
					odids = this.selectedRowKeys.join()
				}
				this.$confirm({
					title: '是否操作订单取消？',
					onOk: () => {
						http({
							method: 'post',
							url: '/api.order.nocheck/',
							data: {
								order_id: odids
							}
						}).then((res) => {
							if (res.code == "200") {
								this.$message.success('订单操作成功！');
								this.selectedRowKeys = []
								this.getTable()
							} else {
								this.$message.warning(res.message);
							}
						})
					},
					onCancel() {
						console.log('Cancel');
					},
					class: 'test',
				});
				
			}
		},
	};
</script>

<style lang="less" scoped>
	.btnss {
		display: flex;
		align-items: center;

		p {
			margin-bottom: 0px;
			margin-left: 10px;
		}
	}

	.boboox {
		margin-bottom: 20px;

		&>p {
			display: flex;
			line-height: 14px;

			&:before {
				content: '';
				width: 3px;
				position: relative;
				top: 1px;
				height: 14px;
				background: #3473FF;
				margin-right: 6px;
			}
		}
	}

	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>