<template>
	<div class="content-box-div tabtopbb">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select
					    v-model="corp_id"
						 @change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="网点名称">
					<a-input size="default" v-model="node_name" placeholder="输入网点名称查询">
					</a-input>
				</a-form-model-item> 
				<a-form-model-item label="日期查询">
					<a-range-picker @change="onChange" :disabledDate="disabledDate" format="YYYY-MM-DD HH:mm:ss" show-time>
						<a-icon slot="suffixIcon" type="smile" />
					</a-range-picker>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button type="primary" icon="plus-circle" @click="dcClick">
				导出
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="detype" slot-scope="text, record">
					<a-tag color="blue" v-for="(item, index) in record.detype" :key="index">
					    {{item.type_name}}: {{item.de_amount}}
					 </a-tag>
				</div>
				<div slot="age6" slot-scope="tags">
					<span class="btn-span">
						编辑
					</span>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>


	</div>
</template>

<script>
	import http from '@/http'
	import moment from 'moment'
	const columns = [{
			title: '存款日期',
			dataIndex: 'add_date',
			key: 'add_date',
		},
		{
			title: '网点名称',
			dataIndex: 'node_name',
			key: 'node_name',
		},
		{
			title: '设备名称',
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: '设备编号',
			key: 'number',
			dataIndex: 'number',
		},
		
		{
			title: '总金额',
			key: 'age3',
			dataIndex: 'age3',
			children: [{
					title: '纸币金额',
					dataIndex: 'cash_amount',
					key: 'cash_amount',
					align: 'center'
				},
				{
					title: '信封金额',
					dataIndex: 'envelope_amount',
					key: 'envelope_amount',
					align: 'center'
				},
			],
		},
		

		{
			title: '异常金额',
			key: 'un_amount',
			dataIndex: 'un_amount'
		},

		{
			title: '正常金额',
			key: 'total_amount',
			dataIndex: 'total_amount'
		},

		{
			title: '汇总金额',
			key: 'amount',
			dataIndex: 'amount'
		},
		{
			title: '付款项目明细',
			key: 'detype',
			align: 'detype',
			scopedSlots: {
				customRender: 'detype'
			},
		},
	];

	export default {
		data() {
			return {
				modelVisible: false,
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				columns,
				
				node_name: '',
				start_date: '',
				end_date: '',
				corp_id: null
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}
		
				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {
							...corpTreeList
						}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			disabledDate (current) {
			  return current && current > moment().endOf('day')
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			handleSubmit(e) {
			},
			
			handleOk() {
				this.modelVisible = false;
			},
			handleCancel() {
				this.modelVisible = false;
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.node_name = '' 
					this.start_date = ''
					this.end_date = ''
					this.tablePage = 1
					this.filterKey = Math.random()
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable () {
				http({
					method: 'post',
					url: '/api.deposit.collect/',
					data: {
						page: this.tablePage,
						node_name: this.node_name,
						start_date: this.start_date,
						end_date: this.end_date,
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			onChange (data) {
				this.start_date = moment(data[0]).format('YYYY-MM-DD HH:mm:ss')
				this.end_date = moment(data[1]).format('YYYY-MM-DD HH:mm:ss')
			},
			dcClick () {
				http({
					method: 'post',
					url: '/api.account.dsdata.export/',
					data: {
						node_name: this.node_name,
						start_date: this.start_date,
						end_date: this.end_date,
						corp_id: this.corp_id
					}
				}).then((res) => {
					if (res.code == '200') {
						window.open(res.data)
					} else {
						this.$message.error(res.message);
					}
				})
			}
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
