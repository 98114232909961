import { render, staticRenderFns } from "./lock.vue?vue&type=template&id=2cda1130&scoped=true&"
import script from "./lock.vue?vue&type=script&lang=js&"
export * from "./lock.vue?vue&type=script&lang=js&"
import style0 from "./lock.vue?vue&type=style&index=0&id=2cda1130&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cda1130",
  null
  
)

export default component.exports