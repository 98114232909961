<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select
					    v-model="corp_id"
						 @change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input size="default" v-model="number" placeholder="输入设备编号查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="设备名称">
					<a-input size="default" v-model="name" placeholder="输入设备名称查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="icon" slot-scope="text, record">
					<a-avatar shape="square" :src="record.icon" :size="64" />
				</div>
				<div slot="status" slot-scope="text, record">
					<a-tag color="blue" v-if="record.status == 0">
						启动
					</a-tag>
					<a-tag color="orange" v-if="record.status == 1">
						禁用
					</a-tag>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>
	</div>
</template>

<script>
	import http from '@/http'
	
	const columns = [{
			title: '所属客户',
			dataIndex: 'corp_name',
			key: 'corp_name',
		},

		{
			title: '网点名称',
			key: 'node_name	',
			dataIndex: 'node_name',
		},
		{
			title: '设备名称',
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: '设备编号',
			key: 'number',
			dataIndex: 'number',
		},
		{
			title: '在机金额',
			key: 'total_amount',
			dataIndex: 'total_amount',
		},
		{
			title: '钞袋状态',
			key: 'cashbag_status',
			dataIndex: 'cashbag_status',
		},
		{
			title: '打印机状态',
			key: 'printer_status',
			dataIndex: 'printer_status',
		},
		{
			title: '保险箱门',
			key: 'strongbox_status',
			dataIndex: 'strongbox_status',
		},
		{
			title: '设备状态',
			key: 'status_txt',
			dataIndex: 'status_txt',
		},
		{
			title: '错误信息',
			key: 'status_error',
			dataIndex: 'status_error',
		},
		{
			title: '最后上报时间',
			key: 'update_time',
			dataIndex: 'update_time',
		}
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				form: {
					id: 0,	
					type_id: undefined,
					name: '',	
					number: '',	
					corp_id: undefined,
					node_id: undefined,
					lock_id: undefined,
				},

				corp_id: null,
				number: '',
				name: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				formKey: Math.random(),
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}
				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {...corpTreeList}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			handleSubmit(e) {
			},
			corpChange(data) {
				this.corp_id = data
			},
			chcorpChange(data) {
				this.form.corp_id = data[data.length - 1]
				this.getFormData()
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.number= ''
					this.name= ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.device.status/',
					data: {
						name: this.name,
						number: this.number,
						page: this.tablePage,
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					id: 0,
					type_id: undefined,
					name: '',	
					number: '',	
					corp_id: undefined,
					node_id: undefined,
					lock_id: undefined,
				}
				this.formKey = Math.random()
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
