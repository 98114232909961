<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select v-model="corp_id" @change="corpChange" style="width: 300px;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{ children: 'list', title: 'corp_name', key: 'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">

						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button type="primary" @click="modelVisible = true" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="icon" slot-scope="text, record">
					<a-avatar shape="square" :src="record.icon" :size="64" />
				</div>
				<div slot="settle_model" slot-scope="text, record">
					<a-tag color="blue" v-if="record.settle_model == 0">
						普通模式
					</a-tag>
					<a-tag color="orange" v-if="record.settle_model == 1">
						资金划拨模式
					</a-tag>
				</div>
				<div slot="status" slot-scope="text, record">
					<a-tag color="blue" v-if="record.status == 0">
						启用
					</a-tag>
					<a-tag color="orange" v-if="record.status == 1">
						禁止
					</a-tag>
				</div>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" @click="edit(record)">
						编辑
					</span>
					<span class="btn-span" @click="setSettle(record)" v-if="record.settle_model == 1">
						设置账户
					</span>
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.de_id)">
						<template slot="title">
							<p>是否确定删除该存款类型？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
					:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>

		<a-modal :title="!form.de_id ? '添加' : '编辑'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :key="formKey" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="选择客户" prop="corp_id">
					<a-tree-select v-model="form.corp_id" style="width: 100%;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{ children: 'list', title: 'corp_name', key: 'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="类型名称" prop="type_name">
					<a-input v-model="form.type_name" placeholder="请输入类型名称,长度限制八位" maxlength="8" />
				</a-form-model-item>
				<a-form-model-item label="类型图标" prop="icon">
					<a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
						:customRequest="customRequest">
						<img style="width: 100px;" v-if="form.icon" :src="form.icon" alt="avatar" />
						<div v-else>
							<a-icon :type="loading ? 'loading' : 'plus'" />
							<div class="ant-upload-text">
								Upload
							</div>
						</div>
					</a-upload>
				</a-form-model-item>
				<a-form-model-item label="模式">
					<a-radio-group v-model="form.settle_model">
						<a-radio :value="0">
							普通模式
						</a-radio>
						<a-radio :value="1">
							资金划拨模式
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="具体类型" prop="otype">
					<a-radio-group v-model="form.otype">
						<a-radio :value="0">
							不设置
						</a-radio>
						<a-radio :value="1">
							油品
						</a-radio>
						<a-radio :value="2">
							非油品
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="类型状态">
					<a-radio-group v-model="form.status">
						<a-radio :value="0">
							启用
						</a-radio>
						<a-radio :value="1">
							禁止
						</a-radio>
					</a-radio-group>
				</a-form-model-item>

			</a-form-model>
		</a-modal>

		<a-modal title="设置账户" :visible="accModelVisible" @ok="accHandleOk" @cancel="accHandleCancel" :width="680">
			<a-table :columns="collist" :data-source="dmlist" :pagination="false" bordered size="small" rowKey="dm_id">
				<div slot="cba_id" slot-scope="cba_id, rows">
					<a-select placeholder="请选择" v-model="rows.cba_id"
						style="width: 100%;max-width: 420px; overflow: hidden;">
						<a-select-option v-for="(item, index) in accList" :key="index" :value="item.cba_id">
							{{ item.account }}-{{ item.name }}-{{ item.bank }}
						</a-select-option>
					</a-select>
				</div>

				<div slot="settle_ratio" slot-scope="settle_ratio, rows">
					<a-input-number placeholder="请输入比例" :min="0" :max="1" :precision="2" v-model="rows.settle_ratio" />
				</div>

				<div slot="setting" style="cursor: pointer" slot-scope="name, rows">
					<a-icon type="plus" @click="addM" />
					<a-icon type="minus" @click="removeM(rows)" style="margin-left: 4px" />
				</div>

				<template slot="footer">
					<a style="text-align: center;display: block;line-height: 40px;" @click="addM">
						<a-icon type="plus-circle" /> 添加
					</a>
				</template>
			</a-table>
		</a-modal>
	</div>
</template>

<script>
import http from '@/http'
const columns = [{
	title: '客户名称',
	dataIndex: 'corp_name',
	key: 'corp_name',
},
{
	title: '类型名称',
	key: 'type_name	',
	dataIndex: 'type_name',
},
{
	title: '类型图标',
	key: 'icon',
	dataIndex: 'icon',
	scopedSlots: {
		customRender: 'icon'
	},
},
{
	title: '模式',
	key: 'settle_model',
	dataIndex: 'settle_model',
	scopedSlots: {
		customRender: 'settle_model'
	},
},
{
	title: '状态',
	key: 'status',
	dataIndex: 'status',
	scopedSlots: {
		customRender: 'status'
	},
},
{
	title: '操作',
	key: 'age6',
	width: 250,
	scopedSlots: {
		customRender: 'age6'
	},
},
];
const collist = [
	{
		title: "结算账号",
		key: 'cba_id',
		dataIndex: "cba_id",
		scopedSlots: {
			customRender: "cba_id"
		},
		width: 450,
	},
	{
		title: "结算比例",
		key: 'settle_ratio',
		dataIndex: "settle_ratio",
		scopedSlots: {
			customRender: "settle_ratio"
		},
		width: 100,
	},
	{
		title: "操作",
		key: 'setting',
		dataIndex: "setting",
		scopedSlots: {
			customRender: "setting"
		},
	},
];

function getInfo() {
	return {
		cba_id: null,
		settle_ratio: 0.00,
		dm_id: Math.random() * 1000
	}
}
export default {
	data() {
		return {
			modelVisible: false,
			accModelVisible: false,
			columns,
			collist,
			labelCol: {
				span: 6
			},
			wrapperCol: {
				span: 14
			},
			loading: false,
			accList: [],
			dmlist: [],
			form: {
				corp_id: null,
				type_name: '',
				icon: '',
				de_id: 0,
				otype: 0,
				settle_model: 0,
				status: 0,
			},
			rules: {
				type_name: [{
					required: true,
					message: '请输入网点名称',
					trigger: 'blur'
				}],
				corp_id: [{
					required: true,
					message: '请选择客户',
					trigger: 'change'
				}],
				icon: [{
					required: true,
					message: '请上传类型图标',
					trigger: 'change'
				}],
			},
			dmid:null,
			corp_id: null,
			tableData: [],
			tableTotal: 0,
			tablePage: 1,
			corpList: [],
			corpTreeList: [],
			defaultCorp: [],
			formKey: Math.random(),
			accFormKey: Math.random()
		};
	},
	mounted() {
		http({
			method: 'post',
			url: '/api.corp.treelist/',
			data: {}
		}).then((res) => {
			this.corpList = res.data
			let corpTreeList = {}
			function forData(data, ids) {
				data.forEach((v) => {
					corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
					if (v.list.length) {
						forData(v.list, [...ids, v.corp_id])
					}
				})
			}
			res.data.forEach((v, i) => {
				corpTreeList[v.corp_name + v.corp_id] = v.corp_id
				if (v.list.length) {
					forData(v.list, [v.corp_id])
				}
				if (i == 0) {
					let defaultCorp = { ...corpTreeList }
					let keys = Object.keys(defaultCorp)
					this.defaultCorp = v.corp_id
				}
			})
			this.corpTreeList = corpTreeList
			this.corp_id = this.defaultCorp
			this.getTable()
		})
	},
	methods: {
		customRequest(data) {
			let formData = new FormData()
			// 向formData实例中追加属性 (参数1：属性名 参数2：属性值)
			formData.append('file', data.file)
			http({
				method: 'post',
				url: '/api.config.upload.image/',
				ContentType: { 'Content-Type': 'multipart/form-data' },
				data: {
					isFile: true,
					file: data.file
				}
			}).then((res) => {
				this.form.icon = res.data
			})
		},
		corpChange(data) {
			this.corp_id = data
		},
		getAccList(corp_id) {
			http({
				method: 'post',
				url: '/api.corp.acc.list/',
				data: {
					corp_id: corp_id
				}
			}).then((res) => {
				this.accList = res.data
			})
		},
		btnClick(type) {
			if (type == '查询') {
				this.getTable()
			} else if (type == '重置') {
				this.corp_id = this.defaultCorp
				this.tablePage = 1
				this.getTable()
			} else if (type == '刷新') {
				this.getTable()
			}
		},
		getTable() {
			http({
				method: 'post',
				url: '/api.deposit.type.list/',
				data: {
					page: this.tablePage,
					corp_id: this.corp_id
				}
			}).then((res) => {
				this.tableData = res.data.list
				this.tableTotal = res.data.total_count
			})
		},
		tabelPageChange(page) {
			this.tablePage = page
			this.getTable()
		},
		remove(id) {
			http({
				method: 'post',
				url: '/api.deposit.type.del/',
				data: {
					de_id: id,
				}
			}).then((res) => {
				if (res.code == "200") {
					this.$message.success('删除类型存款成功');
					this.getTable()
				} else {
					this.$message.warning(res.message);
				}
			})
		},
		setSettle(data) {
			this.accModelVisible = true;
			this.accFormKey = Math.random()
			this.getAccList(data.corp_id);
			this.getDmList(data.de_id);
			this.dmid = data.de_id;
		},
		getDmList(de_id) {
			http({
				method: 'post',
				url: '/api.deposit.type.dmlist/',
				data: {
					de_id: de_id
				}
			}).then((res) => {
				if (res.code == "200") {
					this.dmlist = res.data;
				}
			})
		},
		addM() {
			this.dmlist.push(getInfo())
		},
		removeM(data) {
			this.dmlist = this.dmlist.filter((v) => {
				if (v.dm_id == data.dm_id) {
					return false
				}
				return true
			})
		},
		edit(data) {
			this.getAccList(data.corp_id)
			let cp = this.corpTreeList[data.corp_name + data.corp_id]
			this.form = {
				...data,
				corp_id: typeof cp == 'number' ? cp : cp[cp.length - 1]
			}
			this.modelVisible = true;
			this.formKey = Math.random()
		},

		handleOk() {
			this.$refs.ruleForm.validate(valid => {
				if (valid) {
					let newData = {
						...this.form
					}
					delete newData.corp_name
					if (typeof newData.corp_id == 'object') {
						newData.corp_id = newData.corp_id[newData.corp_id.length - 1]
					}
					http({
						method: 'post',
						url: '/api.deposit.type.add/',
						data: newData
					}).then((res) => {
						if (res.code == "200") {
							if (this.form.de_id) {
								this.$message.success('编辑存款类型信息成功');
							} else {
								this.$message.success('添加存款类型成功');
							}
							this.getTable()
							this.modelVisible = false;
							this.initFormData()
						} else {
							this.$message.warning(res.message);
						}
					})
				} else {
					return false;
				}
			});

		},
		handleCancel() {
			this.modelVisible = false;
			this.initFormData()
		},
		accHandleOk() {
			http({
				method: 'post',
				url: '/api.deposit.type.adddm/',
				data: {
					data:JSON.stringify(this.dmlist),
					de_id:this.dmid
				}
			}).then((res) => {
				if (res.code == "200") {
					this.$message.success('结算账号设置成功');
					this.accModelVisible = false;
					this.dmlist = [];
					this.dmid = null;
				} else {
					this.$message.warning(res.message);
				}
			})
		},
		accHandleCancel() {
			this.accModelVisible = false;
			this.dmlist = [];
			this.dmid = null;
		},
		initFormData() {
			this.form = {
				corp_id: null,
				type_name: '',
				icon: '',
				de_id: 0,
				otype: 0,
				settle_model: 0,
				status: 0,

			}
			this.formKey = Math.random()
		},
	},
};
</script>

<style lang="less" scoped>
.table-pagination {
	text-align: right;
	padding: 20px 0px;
}

::v-deep .ant-table-body {
	margin: 0;
}

.filter-box {
	padding: 20px;
}

.btns10 {
	margin-left: 30px;

	button {
		margin-right: 10px;
	}
}

.table-box {
	padding: 0px 20px;
}

.ew-filter {
	display: flex;
	justify-content: space-between;
	padding: 0px 20px;
	padding-bottom: 20px;
	align-items: center;
}
</style>
