<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select
					    v-model="corp_id"
						 @change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">

						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button type="primary" @click="modelVisible = true" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="icon" slot-scope="text, record">
					<a-avatar shape="square" :src="record.icon" :size="64" />
				</div>
				<div slot="method" slot-scope="text, record">
					<a-tag color="blue" v-if="record.method == 1">
						手工结算
					</a-tag>
					<a-tag color="pink" v-if="record.method == 2">
						自动结算
					</a-tag>
				</div>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" @click="edit(record)">
						编辑
					</span>
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.settle_id)">
						<template slot="title">
							<p>是否确定删除该日结方式？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>

		<a-modal :title="!form.settle_id ? '添加' :'编辑'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :key="formKey" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="选择客户" prop="corp_id">
					<a-tree-select v-model="form.corp_id" @change="chcorpChange" style="width: 100%;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="结算方式" prop="method">
					<a-radio-group v-model="form.method">
						<a-radio :value="1">
							手工结算
						</a-radio>
						<a-radio :value="2">
							自动结算
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="结算时间" prop="do_time" v-if="form.method == 2">
					<a-time-picker v-model="form.do_time" style="width: 100%;" format="HH:mm">
					</a-time-picker>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	import moment from 'moment'
	const columns = [{
			title: '客户名称',
			dataIndex: 'corp_name',
			key: 'corp_name',
		},

		{
			title: '结算方式',
			key: 'method',
			dataIndex: 'method',
			scopedSlots: {
				customRender: 'method'
			},
		},
		{
			title: '自动结算时间',
			key: 'do_time',
			dataIndex: 'do_time',
		},
		{
			title: '操作',
			key: 'age6',
			width: 250,
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				form: {
					corp_id: null,
					method: 1,
					do_time: moment('18:00', 'HH:mm'),
					settle_id: 0,
				},
				rules: {
					method: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					corp_id: [{
						required: true,
						message: '请选择客户',
						trigger: 'change'
					}],
					do_time: [{
						required: true,
						message: '请选择自动结算时间',
						trigger: 'change'
					}],
				},

				corp_id: null,
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				formKey: Math.random()
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}
				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {...corpTreeList}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			customRequest (data) {
				http({
					method: 'post',
					url: '/api.config.upload.image/',
					data: {
						file: data.file
					}
				}).then((res) => {
					
				})
			},
			corpChange(data) {
				this.corp_id = data
			},
			chcorpChange(data) {
				this.form.corp_id = data
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.corp.settle.list/',
					data: {
						page: this.tablePage,
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.corp.settle.del/',
					data: {
						settle_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除日结方式成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				let cp = this.corpTreeList[data.corp_name + data.corp_id]
				this.form = {
					...data,
					corp_id: typeof cp == 'number' ? cp : cp[cp.length - 1],
					settle_id: data.settle_id,
					do_time: moment(data.do_time, 'HH:mm')
				}
				this.modelVisible = true;
				this.formKey = Math.random()
			},

			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form
						}
						delete newData.corp_name
						if (typeof newData.corp_id == 'object') {
							newData.corp_id = newData.corp_id[newData.corp_id.length - 1]
						}
						if (newData.method == 1) {
							newData.do_time = ''
						} else {
							newData.do_time = moment(newData.do_time).format('HH:mm')
						}
						http({
							method: 'post',
							url: '/api.corp.settle.add/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.settle_id) {
									this.$message.success('编辑日结方式信息成功');
								} else {
									this.$message.success('添加日结方式成功');
								}
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					corp_id: null,
					method: 1,
					do_time: moment('00:00', 'HH:mm'),
					settle_id: 0,
				}
				this.formKey = Math.random()
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
