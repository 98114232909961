<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="用户名">
					<a-input size="default" v-model="admin_name" placeholder="输入用户名查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">

						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button type="primary" @click="addUser" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="is_lock" slot-scope="text, record">
					<a-tag color="blue" v-if="record.is_lock == 0">
						启动
					</a-tag>
					<a-tag color="orange" v-if="record.is_lock == 1">
						锁定
					</a-tag>
				</div>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" @click="edit(record)">
						编辑
					</span>
					<span class="btn-span btn-span-delete" v-if="record.is_lock == 0"
						@click="lockChange(record.is_lock, record.admin_id)">
						禁用
					</span>
					<span class="btn-span btn-span-delete" v-if="record.is_lock == 1"
						@click="lockChange(record.is_lock, record.admin_id)">
						启动
					</span>
					<span class="btn-span" @click="updatepwd(record.admin_id)">
						初始化密码
					</span>
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.admin_id)">
						<template slot="title">
							<p>是否确定删除该用户名？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>

		<a-modal :title="form.admin_id ? '编辑' : '添加'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :key="formKey" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="选择客户" prop="corp_id">
					<a-tree-select v-model="form.corp_id" @change="corpChange" style="width: 100%;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="选择角色" prop="role_id">
					<a-select :disabled="roleDisabled" v-model="form.role_id" style="width: 100%" placeholder="请选择角色权限">
						<a-select-option :value="item.role_id" v-for="(item, index) in roleList" :key="index">
							{{item.role_name}}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="用户名" prop="admin_name">
					<a-input v-model="form.admin_name" placeholder="请输入用户名" />
				</a-form-model-item>
				<a-form-model-item label="姓名" prop="real_name">
					<a-input v-model="form.real_name" placeholder="请输入姓名" />
				</a-form-model-item>
				<a-form-model-item label="联系方式" prop="contact">
					<a-input v-model="form.contact" placeholder="请输入联系方式" />
				</a-form-model-item>
				<a-form-model-item label="手机号码" prop="mobile">
					<a-input v-model="form.mobile" placeholder="请输入手机号码" />
				</a-form-model-item>

			</a-form-model>
		</a-modal>

		<a-modal title="重置管理员密码" :visible="modelPasswordVisible" @ok="handlePasswordOk" @cancel="handlePasswordCancel">
			<a-form-model ref="ruleForm" :model="passwordForm" :rules="passwordRules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="密码" prop="password">
					<a-input-password v-model="passwordForm.password" placeholder="请输入密码" />
				</a-form-model-item>
				<a-form-model-item label="确认密码" prop="npassword">
					<a-input-password v-model="passwordForm.npassword" placeholder="请输入确认密码" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	const columns = [{
			title: '客户',
			dataIndex: 'corp_name',
			key: 'corp_name',
		},
		{
			title: '用户名',
			key: 'admin_name',
			dataIndex: 'admin_name',
		},
		{
			title: '姓名',
			key: 'real_name',
			dataIndex: 'real_name',
		},
		{
			title: '角色名',
			key: 'role_name',
			dataIndex: 'role_name',
		},

		{
			title: '联系方式',
			key: 'contact',
			dataIndex: 'contact',
		},
		{
			title: '手机号码',
			key: 'mobile',
			dataIndex: 'mobile',
		},

		{
			title: '状态',
			key: 'is_lock',
			dataIndex: 'is_lock',
			scopedSlots: {
				customRender: 'is_lock'
			},
		},
		{
			title: '操作',
			key: 'age6',
			width: 250,
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,

				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				form: {
					admin_id: 0,
					admin_name: '',
					real_name: '',
					contact: '',
					mobile: '',
					corp_id: localStorage.getItem('type_id') == 0 ? 0 : null,
					role_id: null
				},
				rules: {
					admin_name: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					real_name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					corp_id: [{
						required: true,
						message: '请选择客户',
						trigger: 'change'
					}],
					role_id: [{
						required: true,
						message: '请选择角色',
						trigger: 'change'
					}],
				},

				admin_name: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				roleList: [],
				corpList: [],
				corpTreeList: [],
				formKey: Math.random(),

				modelPasswordVisible: false,
				passwordForm: {
					admin_id: 0,
					password: '',
					npassword: ''
				},
				passwordRules: {
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 20,
						message: '密码最小长度为6位数',
						trigger: 'blur'
					}],
					npassword: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 20,
						message: '密码最小长度为6位数',
						trigger: 'blur'
					}],
				},
				glyCorp: [],
				yysCorp: [],
				
				roleDisabled: false
			};
		},
		mounted() {
			this.getTable()
			http({
				method: 'post',
				url: '/api.admin.role.treelist/',
				data: {
					type_id: parseInt(localStorage.getItem('type_id'))
				}
			}).then((res) => {
				this.roleList = res.data
				if (this.roleList.length > 0) {
					this.form.role_id = this.roleList[0].role_id
				}
			})
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				if (localStorage.getItem('type_id') == 0) {
					this.corpList = [{
						corp_id: 0,
						corp_name: '平台管理',
						list: []
					}, ...res.data]
					this.glyCorp = [...this.corpList]
				} else {
					this.corpList = res.data
				}
				this.yysCorp = [...res.data]
				let corpTreeList = {}
				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
				})
				let keys = Object.keys(corpTreeList)
				this.corpTreeList = corpTreeList
				this.getTable()
			})
		},
		methods: {
			addUser () {
				if (localStorage.getItem('type_id') == 0) {
					this.roleDisabled = true
					if (this.form.corp_id == 0) {
						this.form.role_id = 1
					}
				} else {
					this.roleDisabled = false
				}
				this.modelVisible = true
			},
			handleSubmit(e) {
			},

			corpChange(data) {
				this.form.corp_id = data
				if (!this.form.admin_id) {
					if (this.form.corp_id == 0) {
						this.form.role_id = 1
					} else {
						this.form.role_id = 2
					}
				}
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.admin_name = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.admin.list/',
					data: {
						admin_name: this.admin_name,
						page: this.tablePage
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			lockChange(type, id) {
				let url = type == 0 ? '/api.admin.tolock/' : '/api.admin.nolock/'
				http({
					method: 'post',
					url: url,
					data: {
						admin_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success(type == 0 ? '管理员锁定成功' : '管理员解锁成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.admin.del/',
					data: {
						admin_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除管理员成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				http({
					method: 'post',
					url: '/api.admin.role.treelist/',
					data: {
						type_id: data.type_id
					}
				}).then((res) => {
					this.roleList = res.data
					let corp_id = this.corpTreeList[data.corp_name + data.corp_id]
					if (data.type_id == 0 || data.type_id == 1) {
						this.corpList = [...this.glyCorp]
					} else {
						this.corpList = [...this.yysCorp]
					}
					if (typeof corp_id == 'object') {
						corp_id = corp_id[corp_id.length - 1]
					}
					this.form = {
						...data,
						corp_id: corp_id || 0
					}
					this.modelVisible = true;
					this.formKey = Math.random()
				})
			},

			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form,
							corp_id: this.form.corp_id == 0 ? 0 : typeof this.form.corp_id == 'object' ? this.form.corp_id[this.form.corp_id.length -
								1] : this.form.corp_id
						}
						delete newData.corp_name
						delete newData.is_lock
						delete newData.role_name
						delete newData.type_id
						http({
							method: 'post',
							url: '/api.admin.add/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.role_id) {
									this.$message.success('编辑管理员信息成功');
								} else {
									this.$message.success('添加管理员成功');
								}
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					admin_id: 0,
					admin_name: '',
					real_name: '',
					contact: '',
					mobile: '',
					corp_id: localStorage.getItem('type_id') == 0 ? 0 : null,
					role_id: this.roleList[0].role_id
				}
				this.roleDisabled = false
				if (localStorage.getItem('type_id') == 0) {
					this.corpList = [...this.glyCorp]
				} else {
					this.corpList = [...this.yysCorp]
				}
				http({
					method: 'post',
					url: '/api.admin.role.treelist/',
					data: {
						type_id: parseInt(localStorage.getItem('type_id'))
					}
				}).then((res) => {
					this.roleList = res.data
					if (this.roleList.length > 0) {
						this.form.role_id = this.roleList[0].role_id
					}
				})
				this.formKey = Math.random()
			},
			handlePasswordOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						if (this.passwordForm.password !== this.passwordForm.npassword) {
							this.$message.warning('两次密码不相同，请检查');
							return
						}
						let newData = {
							...this.passwordForm
						}
						http({
							method: 'post',
							url: '/api.admin.updatepwd/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								this.$message.success('重置管理员密码成功');
								this.getTable()
								this.modelPasswordVisible = false;
								this.passwordForm = {
									admin_id: 0,
									password: '',
									npassword: ''
								}
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			updatepwd(admin_id) {
				this.modelPasswordVisible = true
				this.passwordForm = {
					admin_id: admin_id,
					password: '',
					npassword: ''
				}
			},
			handlePasswordCancel() {
				this.modelPasswordVisible = false;
				this.passwordForm = {
					admin_id: 0,
					password: '',
					npassword: ''
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
