<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" :treeCheckStrictly="true" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select
					    v-model="corp_id"
						@change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="客户名称">
					<a-input size="default" v-model="corp_name" placeholder="输入客户名称查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button type="primary" @click="modelVisible = true" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="province" slot-scope="text, record">
					<span>{{record.province}} {{record.city}} {{record.district}}</span>
				</div>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" @click="addParent(record)">
						添加客户
					</span>
					<span class="btn-span" @click="edit(record)">
						编辑
					</span>
					<span class="btn-span" @click="cedit(record)">
						配置LOGO
					</span>
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.corp_id)">
						<template slot="title">
							<p>是否确定删除该客户？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>

		<a-modal :title="!form.corp_id ? '添加' :'编辑'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel" width="800px">
			<a-form-model :key="formKey"  ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item label="所属客户" v-if="parent_Name">
					{{parent_Name}}
				</a-form-model-item>
				<a-form-model-item label="客户名称" prop="corp_name">
					<a-input v-model="form.corp_name" placeholder="请输入客户名称" />
				</a-form-model-item>
				<a-form-model-item label="地区选择" ref="province" prop="province">
					<city-select @change="selectArea" :province="[form.province]" :city="[form.city]" :district="[form.district]"/>
				</a-form-model-item>
				<a-form-model-item label="详细地址" prop="address">
					<a-input v-model="form.address" placeholder="请输入详细地址" />
				</a-form-model-item>
				<a-form-model-item label="联系人" prop="contact_name">
					<a-input v-model="form.contact_name" placeholder="请输入联系人" />
				</a-form-model-item>
				<a-form-model-item label="联系方式" prop="contact_phone">
					<a-input v-model="form.contact_phone" placeholder="请输入联系方式" />
				</a-form-model-item>
				<a-form-model-item label="服务热线" prop="service_tel">
					<a-input v-model="form.service_tel" placeholder="请输入服务热线" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>

		<a-modal :title="!cform.corp_id ? '添加' :'编辑'" :visible="modelcVisible" @ok="handlecOk" @cancel="handlecCancel">
			<a-form-model :key="cformKey" ref="rulecForm" :model="cform" :rules="crules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="配置LOGO" prop="icon">
					<a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
						:customRequest="customRequest" :before-upload="beforeUpload" accept="image/png">
						<img style="width: 100px;" v-if="cform.logo" :src="cform.logo" alt="logo" />
						<div v-else>
							<a-icon :type="loading ? 'loading' : 'plus'" />
							<div class="ant-upload-text">
								Upload
							</div>
						</div>
					</a-upload>
				</a-form-model-item>

			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	import CitySelect from '@/components/City1/index.vue'
	const columns = [
		{
			title: '客户名称',
			dataIndex: 'corp_name',
			key: 'corp_name',
		},
		{
			title: '所属地区',
			key: 'province',
			dataIndex: 'province',
			scopedSlots: {
				customRender: 'province'
			},
		},
		{
			title: '详细地址',
			key: 'address',
			dataIndex: 'address',
		},
		{
			title: '联系人',
			key: 'contact_name',
			dataIndex: 'contact_name',
		},
		{
			title: '联系电话',
			key: 'contact_phone',
			dataIndex: 'contact_phone',
		},
		{
			title: '操作',
			key: 'age6',
			width: 300,
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];

	export default {
		data() {
			return {
				modelVisible: false,
				modelcVisible: false,

				columns,

				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 16
				},
				other: '',
				form: {
					corp_id: 0,
					corp_name: '',
					parent_id: 0,
					province: '',
					city: '',
					district: '',
					address: '',
					contact_name: '',
					contact_phone: '',
					service_tel: ''
				},
				cform: {
					corp_id: 0,
					logo: ''
				},
				rules: {
					corp_name: [{
						required: true,
						message: '请输入客户名称',
						trigger: 'blur'
					}],
					province: [{
						required: true,
						message: '请选择省市区',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}],
					contact_name: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}],
					contact_phone: [{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur'
					}],
					service_tel: [{
						required: true,
						message: '请输入服务热线',
						trigger: 'blur'
					}],
				},
				crules: {
					logo: [{
						required: true,
						message: '请配置LOGO',
						trigger: 'blur'
					}],
				},
				
				corp_name: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				
				formKey: Math.random(),
				parent_Name: '',
				parent_id: 0,
				corp_id: null,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				cformKey: Math.random()
			};
		},
		components: {
			CitySelect
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}
			
				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {
							...corpTreeList
						}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			customRequest(data) {
				let formData = new FormData()
				// 向formData实例中追加属性 (参数1：属性名 参数2：属性值)
				formData.append('file', data.file)
				http({
					method: 'post',
					url: '/api.config.upload.logo/',
					ContentType: { 'Content-Type': 'multipart/form-data' },
					data: {
						isFile: true,
						file: data.file
					}
				}).then((res) => {
					if(res.code=='200'){
						this.cform.logo = res.data;
					}else{
						this.$message.warning(res.message);
					}
				})
			},
			corpChange(data) {
				this.corp_id = data
			},
			handleSubmit(e) {
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.corp_name = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.corp.list/',
					data: {
						corp_name: this.corp_name,
						page: this.tablePage,
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.corp.del/',
					data: {
						corp_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除客户成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				http({
					method: 'post',
					url: '/api.corp.detail/',
					data: {
						corp_id: data.corp_id,
					}
				}).then((res) => {
					this.form = res.data
					this.modelVisible = true;
				})
			},

			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form
						}
						delete newData.logo
						if (!this.form.city) {
							this.$message.warning('请先选择市');
							return
						}
						if (!this.form.district) {
							this.$message.warning('请先选择区');
							return
						}
						http({
							method: 'post',
							url: '/api.corp.add/',
							data: {
								...newData
							}
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.corp_id) {
									this.$message.success('编辑客户信息成功');
								} else {
									this.$message.success('添加客户成功');
								}
								http({
									method: 'post',
									url: '/api.corp.treelist/',
									data: {}
								}).then((res) => {
									this.corpList = res.data
								})
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			addParent(data) {
				this.form = {
					corp_id: 0,
					corp_name: '',
					parent_id: data.corp_id,
					province: '',
					city: '',
					district: '',
					address: '',
					contact_name: '',
					contact_phone: '',
					service_tel: ''
				}
				this.parent_Name = data.corp_name
				this.formKey = Math.random()
				this.modelVisible = true
			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					corp_id: 0,
					corp_name: '',
					parent_id: 0,
					province: '',
					city: '',
					district: '',
					address: '',
					contact_name: '',
					contact_phone: '',
					service_tel: ''
				}
				this.parent_Name = ''
				this.formKey = Math.random()
			},
			cedit(data) {
				http({
					method: 'post',
					url: '/api.corp.detail/',
					data: {
						corp_id: data.corp_id,
					}
				}).then((res) => {
					this.cform = res.data
					this.modelcVisible = true;
				})
			},
			handlecOk() {
				this.$refs.rulecForm.validate(valid => {
					if (valid) {
						http({
							method: 'post',
							url: '/api.corp.logo/',
							data: {
								corp_id:this.cform.corp_id,
								logo:this.cform.logo
							}
						}).then((res) => {
							if (res.code == "200") {
								if (this.cform.corp_id) {
									this.$message.success('编辑客户LOGO成功');
								} else {
									this.$message.success('添加客户LOGO成功');
								}
								http({
									method: 'post',
									url: '/api.corp.treelist/',
									data: {}
								}).then((res) => {
									this.corpList = res.data
								})
								this.getTable()
								this.modelcVisible = false;
								this.initcFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handlecCancel() {
				this.modelcVisible = false;
				this.initcFormData()
			},
			initcFormData() {
				this.cform = {
					corp_id: 0,
					logo: ''
				}
				this.cformKey = Math.random()
			},
			selectArea (data) {
				this.form.province = data[0]
				this.form.city = data[1]
				this.form.district = data[2]
				this.$refs.province.onFieldBlur();
			}
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
