<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="用户名">
					<a-input size="default" v-model="auditor_name" placeholder="输入用户名查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button type="primary" @click="addUser" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="is_lock" slot-scope="text, record">
					<a-tag color="blue" v-if="record.is_lock == 0">
						启动
					</a-tag>
					<a-tag color="orange" v-if="record.is_lock == 1">
						锁定
					</a-tag>
				</div>
				<div slot="do" slot-scope="text, record">
					<span class="btn-span" @click="climg(record)">
						绑定微信公众号
					</span>
					<span class="btn-span" @click="edit(record)">
						编辑
					</span>
					<span class="btn-span btn-span-delete" v-if="record.is_lock == 0"
						@click="lockChange(record.is_lock, record.auditor_id)">
						禁用
					</span>
					<span class="btn-span btn-span-delete" v-if="record.is_lock == 1"
						@click="lockChange(record.is_lock, record.auditor_id)">
						启动
					</span>
					<span class="btn-span" @click="updatepwd(record.auditor_id)">
						初始化密码
					</span>
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.auditor_id)">
						<template slot="title">
							<p>是否确定删除该用户名？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>

		<a-modal :title="form.auditor_id ? '编辑' : '添加'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :key="formKey" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="所属客户" prop="corp_id">
					<a-tree-select v-model="form.corp_id" @change="corpChange" style="width: 100%;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择所属客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="范围类型" prop="atype">
					<a-radio-group v-model="form.atype" @change="atypeChange">
						<a-radio :value="1">
							站长
						</a-radio>
						<a-radio :value="2">
							区域
						</a-radio>
						<a-radio :value="3">
							全部
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="审核角色" prop="arole_id">
					<a-select :disabled="roleDisabled" v-model="form.arole_id" style="width: 100%" placeholder="请选择审核角色">
						<a-select-option :value="item.arole_id" v-for="(item, index) in roleList" :key="index">
							{{item.arole_name}}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="用户名" prop="auditor_name">
					<a-input v-model="form.auditor_name" placeholder="请输入用户名" />
				</a-form-model-item>
				<a-form-model-item label="姓名" prop="real_name">
					<a-input v-model="form.real_name" placeholder="请输入姓名" />
				</a-form-model-item>
				<a-form-model-item label="联系方式" prop="contact">
					<a-input v-model="form.contact" placeholder="请输入联系方式" />
				</a-form-model-item>
				<a-form-model-item label="手机号码" prop="mobile">
					<a-input v-model="form.mobile" placeholder="请输入手机号码" />
				</a-form-model-item>

			</a-form-model>
		</a-modal>

		<a-modal title="重置审核员密码" :visible="modelPasswordVisible" @ok="handlePasswordOk" @cancel="handlePasswordCancel">
			<a-form-model ref="ruleForm" :model="passwordForm" :rules="passwordRules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="密码" prop="password">
					<a-input-password v-model="passwordForm.password" placeholder="请输入密码" />
				</a-form-model-item>
				<a-form-model-item label="确认密码" prop="npassword">
					<a-input-password v-model="passwordForm.npassword" placeholder="请输入确认密码" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		
		<a-modal title="扫码绑定微信公众号" :visible="modelPasswordVisibleImg" @cancel="() => {modelPasswordVisibleImg = false}" width="300px" :footer="null">
			<img style="width: 200px;height: 200px;border: 1px solid #ccc;display: block;margin: 0 auto;" :src="imgsrc" alt="">
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	const columns = [{
			title: '所属客户',
			dataIndex: 'corp_name',
			key: 'corp_name',
		},
		{
			title: '用户名',
			key: 'auditor_name',
			dataIndex: 'auditor_name',
		},
		{
			title: '姓名',
			key: 'real_name',
			dataIndex: 'real_name',
		},
		{
			title: '范围类型',
			key: 'atype_name',
			dataIndex: 'atype_name',
		},
		{
			title: '审核角色',
			key: 'arole_name',
			dataIndex: 'arole_name',
		},
		{
			title: '联系方式',
			key: 'contact',
			dataIndex: 'contact',
		},
		{
			title: '手机号码',
			key: 'mobile',
			dataIndex: 'mobile',
		},
		{
			title: '状态',
			key: 'is_lock',
			dataIndex: 'is_lock',
			scopedSlots: {
				customRender: 'is_lock'
			},
		},
		{
			title: '操作',
			key: 'do',
			width: 360,
			scopedSlots: {
				customRender: 'do'
			},
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				form: {
					auditor_id: 0,
					atype: 1,
					auditor_name: '',
					real_name: '',
					contact: '',
					mobile: '',
					corp_id: 0,
					arole_id: 0
				},
				rules: {
					auditor_name: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					atype: [{
						required: true,
						message: '请选择范围类型',
						trigger: 'change'
					}],
					real_name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					corp_id: [{
						required: true,
						message: '请选择所属客户',
						trigger: 'change'
					}],
					arole_id: [{
						required: true,
						message: '请选择审核角色',
						trigger: 'change'
					}],
				},

				auditor_name: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				roleList: [],
				corpList: [],
				corpTreeList: [],
				formKey: Math.random(),

				modelPasswordVisible: false,
				passwordForm: {
					auditor_id: 0,
					password: '',
					npassword: ''
				},
				passwordRules: {
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 20,
						message: '密码最小长度为6位数',
						trigger: 'blur'
					}],
					npassword: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 20,
						message: '密码最小长度为6位数',
						trigger: 'blur'
					}],
				},				
				roleDisabled: false,
				imgsrc: '',
				modelPasswordVisibleImg: false
			};
		},
		mounted() {
			this.getTable()
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}
				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
				})
				this.corpTreeList = corpTreeList
			})
		},
		methods: {
			climg (data) {
				this.imgsrc = data.qrcode
				this.modelPasswordVisibleImg = true
			},
			addUser () {
				this.roleDisabled = false
				if(this.corpList.length>0){
					this.form.corp_id = this.corpList[0].corp_id;
				}
				this.form.atype = 1
				this.getArole();
				this.modelVisible = true
			},
			corpChange(data) {
				this.form.corp_id = data
				if (!this.form.auditor_id) {
					this.form.atype = 1
				}
				this.getArole();
			},
			atypeChange(){
				this.getArole();
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.auditor_name = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.auditor.list/',
					data: {
						auditor_name: this.auditor_name,
						page: this.tablePage
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			lockChange(type, id) {
				let url = type == 0 ? '/api.auditor.tolock/' : '/api.auditor.nolock/'
				http({
					method: 'post',
					url: url,
					data: {
						auditor_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success(type == 0 ? '审核员锁定成功' : '审核员解锁成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.auditor.del/',
					data: {
						auditor_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除审核员成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				http({
					method: 'post',
					url: '/api.auditor.arole.treelist/',
					data: {
						atype: data.atype,
						corp_id: data.corp_id
					}
				}).then((res) => {
					this.roleList = res.data
					let corp_id = this.corpTreeList[data.corp_name + data.corp_id]
					this.form = {
						...data
					}
					this.modelVisible = true;
					this.formKey = Math.random()
				})
			},
			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form
						}
						delete newData.corp_name
						delete newData.is_lock
						delete newData.arole_name
						delete newData.atype_name
						delete newData.qrcode
						http({
							method: 'post',
							url: '/api.auditor.add/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.auditor_id) {
									this.$message.success('编辑审核员信息成功');
								} else {
									this.$message.success('添加审核员成功');
								}
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			getArole(){
				http({
					method: 'post',
					url: '/api.auditor.arole.treelist/',
					data: {
						atype: this.form.atype,
						corp_id: this.form.corp_id
					}
				}).then((res) => {
					this.roleList = res.data
					if (this.roleList.length > 0) {
						this.form.arole_id = this.roleList[0].arole_id
					}else{
						this.form.arole_id = null ;
					}
				})
			},
			initFormData() {
				this.form = {
					auditor_id: 0,
					atype: 0,
					auditor_name: '',
					real_name: '',
					contact: '',
					mobile: '',
					corp_id: 0,
					arole_id: 0
				}
				this.roleDisabled = false
				this.formKey = Math.random()
			},
			handlePasswordOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						if (this.passwordForm.password !== this.passwordForm.npassword) {
							this.$message.warning('两次密码不相同，请检查');
							return
						}
						let newData = {
							...this.passwordForm
						}
						http({
							method: 'post',
							url: '/api.auditor.updatepwd/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								this.$message.success('重置审核员密码成功');
								this.getTable()
								this.modelPasswordVisible = false;
								this.passwordForm = {
									auditor_id: 0,
									password: '',
									npassword: ''
								}
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			updatepwd(auditor_id) {
				this.modelPasswordVisible = true
				this.passwordForm = {
					auditor_id: auditor_id,
					password: '',
					npassword: ''
				}
			},
			handlePasswordCancel() {
				this.modelPasswordVisible = false;
				this.passwordForm = {
					auditor_id: 0,
					password: '',
					npassword: ''
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
